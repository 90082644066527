<template>
  <div data-testid="container-history-streams">
    <StreamsTable
      ref="refSInactive"
      :streams="streamsInactive"
      :count="streamsInactiveCount"
      :errorSignalConnection="errorSignalConnection"
      :search.sync="search"
      @open-preview-stream-dialog="openPreviewStreamDialog"
      @open-stream-history-dialog="openStreamHistoryDialog"
      @get-streams-history-list="getInactiveStreams"
      v-if="streamsPeerReceiver"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import StreamsTable from "./StreamsTable"
import StreamCommonMixin from "@/mixins/streams/common"

export default {
  name: "streams-list-active",

  components: {
    StreamsTable,
  },

  mixins: [StreamCommonMixin],

  methods: {
    getInactiveStreams(params) {
      let self = this
      self.createSignalConnection().then(() => {
        self.streamsPeerReceiver.getInactiveStreamsHistoryList(
          self.getRequestParams(params)
        )
      })
    },
  },

  computed: {
    ...mapGetters({
      streamsInactive: "streamsInactive",
      streamsInactiveCount: "streamsInactiveCount",
      streamsPeerReceiver: "streamsPeerReceiver",
    }),
  },
}
</script>
